.privacy-page{	
	background-color: #fff;

		.wrapper{
			padding: 60px 40px;
			width: calc(100% - 80px);


			h1{
				color: $black;
				font-size: 30px;
				font-weight: 500;
				margin-bottom: 20px;
				text-align: center;
			}

			h2{
				margin: 0 auto;
		    width: 400px;
		    color: $light-grey;
		    font-size: 22px;
		    text-align: center;
		    margin-bottom: 20px;

		    @media (max-width: 767px) {
					width: 100%;
				}
			}

			.text{
        font-size: 16px;
        line-height: 1.5em;
        text-align: justify;
        font-family: "Avenir";

        p{
        	padding: 10px 0;
        }

        h3{
        	color: $black;
					font-size: 22px;
					font-weight: 500;
					margin: 20px 0;
					text-align: center;
        }
			}
		}
}