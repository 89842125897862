.footer{
	background-color: #f6f6f6;

	.footer-wrapper{
		width: calc(100% - 80px);
		padding: 40px;
	}

	.made{
		float: left;

		a, span{
			color: $main-dark;

			&:hover{
				color: $main-dark;
				cursor: pointer;
				text-decoration: underline;
			}
		}

		@media (max-width: 1023px) {
		  display: block;
		  float: none;
		}

		.version{
			display: block;
			margin-top: 142px;
		}
	}

	.columns{
		width: calc(100% - 400px);
		float: right;

		@media (max-width: 1023px) {
		  display: block;
		  float: none;
		  width: 100%;
		}

		ul{
			list-style: none;
			float: left;
			padding-left: 15px;
			box-sizing: border-box;
			width: 25%;

			@media (max-width: 1023px) {
			  width: 50%;
			  margin-top: 35px;
			  padding-left: 0px;
			}

			@media (max-width: 599px) {
			  width: 100%;
			  margin-top: 35px;
			  padding-left: 0px;
			}

			li{

				a{
					line-height: 35px;
					display: block;

					&:hover{
						color: $main-dark;
						cursor: pointer;
						text-decoration: underline;
					}
				}

				span{
					text-transform: uppercase;
					font-weight: 700;
					display: block;
					letter-spacing: 1px;
					color: $main-font-color;
					margin-bottom: 20px;
				}
			}
	  }

	  .clear-responsive{
	  	@media (max-width: 1023px) {
			  clear: both;
			}
	  }
	}
}