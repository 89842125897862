* {
    margin: 0;
    outline: 0 none;
    padding: 0;
}

html, body, #app{
	height: 100%;
	width: 100%;
}

body{
	font-family: $main-font;
	color: $main-font-color;
	background-color: $background;
	font-size: 14px;
}

#everydaycheck{
	width: 100%;
	height: 100%;
}

a, a:visited, a:hover, a:focus{
	text-decoration: none;
	outline: none;
	color: $main-font-color;
}

.clear{
	clear: both;
}