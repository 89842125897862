.nav {
  .nav-wrapper {
    padding: 30px 40px 20px;
    width: calc(100% - 80px);

    .brand-wrapper {
      display: inline-block;

      &:hover {
        cursor: pointer;
      }

      .logo {
        width: 40px;
        height: 40px;
        background: url("/images/everyday.png") no-repeat scroll 0 0 transparent;
        display: inline-block;
        background-size: 40px 40px;
        float: left;
      }

      .brand {
        font-size: 32px;
        margin-left: 12px;
        line-height: 32px;
        float: left;
        margin-top: 15px;
        color: #333;

        .green {
          color: $main-dark;
        }
      }
    }

    .nav-options {
      float: right;
      line-height: 24px;
      list-style: none;
      margin-top: 10px;

      @media (max-width: 767px) {
        font-size: 10px;
      }

      li {
        display: inline-block;
        margin-left: 25px;

        &:hover {
          cursor: pointer;
          border-bottom: 3px solid $main-dark;

          a {
            color: $main-dark;
          }
        }

        .iphone,
        .android,
        .plus {
          font-size: 20px;
        }

        &.less-margin {
          margin-left: 15px;
        }

        &.nohover {
          &:hover {
            cursor: default;
            border-bottom: 0px;

            a {
              color: $main-font-color;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .nav-wrapper {
      padding: 5px 40px 20px;
    }
  }
}
