.login {
  h2 {
    color: $black;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
  }

  .apple-signin-button {
    margin-top: 15px;
    :hover {
      cursor: pointer;
    }
  }

  .login-form-wrapper {
    margin: 0 auto;
    width: 400px;

    @media (max-width: 460px) {
      width: 100%;
    }

    .errors {
      list-style: none;
      color: #fff;
      margin-bottom: 20px;
      background-color: $main-dark;
      padding: 5px;

      li:first-child {
        margin-top: 10px;
      }
    }

    .form-row {
      margin-bottom: 20px;
      position: relative;
      width: 100%;

      .login-input {
        background-color: #fff;
        border: 2px solid $light-grey;
        color: $black;
        font-size: 16px;
        height: 70px;
        padding: 33px 13px 13px;
        transition: border-color 0.1s ease 0s;
        width: 100%;
        box-sizing: border-box;
      }

      .text-label {
        display: block;
        font-size: 14px;
        font-weight: 600;
        left: 15px;
        padding-right: 30px;
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        top: 19px;
        transition: color 0.1s ease 0s;
        white-space: nowrap;
        width: 100%;
      }

      input:focus {
        border-color: $main-dark;

        ~ .text-label {
          color: $main-dark;
        }
      }
    }

    .login-button {
      display: block;
      background-color: $main-dark;
      color: #fff;
      width: 100%;
      border: 2px solid transparent;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      height: 50px;
      line-height: 20px;
      padding: 13px 23px;
      text-align: center;

      &:hover {
        color: $main-dark;
        border: 3px solid $main-dark;
        background-color: $background;
        box-sizing: border-box;
      }
    }

    .forgot {
      color: $main-dark;
      margin-top: 20px;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .or {
    text-transform: uppercase;
    font-size: 16px;
    margin: 60px auto 20px;
    text-align: center;
  }

  .apple-button {
    padding: 2px;
    height: 38px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    // padding: 12px 16px;
    font-size: 18px;
    // font-weight: bold;
    :hover {
      cursor: pointer;
    }
    cursor: pointer;
  }

  .apple-logo {
    width: 25px;
    height: 25px;
    background-image: url("/images/apple-logo.png");
    background-size: cover;
    margin-right: 8px;
  }

  .apple-sign-in-text {
    font-family: "Arial";
  }
}
