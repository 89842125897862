.pricing-page{
	background-color: #fff;

  .wrapper{
		padding: 60px 40px;
		width: calc(100% - 80px);


		h1{
			color: $black;
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 20px;
			text-align: center;
		}

		h2 {
			margin: 0 auto;
			width: 600px;
			color: $light-grey;
			font-size: 22px;
			text-align: center;
			margin-bottom: 20px;
			line-height: 34px;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		ul{
			display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    margin: 0 auto;
	    margin-bottom: 65px;
	    max-width: 940px;
	    font-family: "Avenir";

	    li.box{
	    	background-color: #fff;
		    box-shadow: 0 4px 35px rgba(0,0,0,0.05), 0 2px 5px rgba(0,0,0,0.05);
		    box-sizing: border-box;
		    margin-bottom: 20px;
		    padding: 20px;
		    padding-bottom: 31px;
		    width: 300px;
		    height: 580px;

		    @media (max-width: 599px) {
				  margin-bottom: 100px;
				}

		    .icon-wrapper{
		    	text-align: center;
		    }

		    ul{
		    	width: 100%;

		    	li{
		    		width: 100%;
		    		margin-bottom: 20px;
		    	}
		    }

		    h1{
		    	color: #333;
			    font-size: 24px;
			    font-weight: 400;
			    line-height: 28px;
			    margin-bottom: 10px;
			    margin-top: 20px;
			    font-family: "euclid-medium";
			    text-align: center;
		    }

		    .price-wrapper{
		    	text-align: center;
		    	
		    	.price-center{
		    		display: inline-block;

			    	.price{
			    		float: left;
			    		color: $main-dark;
			    		font-size: 30px;
			    		font-weight: 900;
			    	}

			    	.price-info{
			    		float: left;
			    		color: $light-grey;
			    		margin-left: 10px;
			    		text-align: left;
			    	}
			    }
		    }

		    ul.features{
		    	box-sizing: border-box;
		    	padding: 20px;
		    	color: $light-grey;
		    	list-style-type: circle;
		    	margin-bottom: 0;
		    	height: 320px;
		    	display: block;
		    }

		    .buttons {
					padding-top: 30px;
					text-align: center;
					width: auto;

					.button {
						text-align: center;
						background-color: $main-dark;
						color: #fff;
						font-size: 14px;
						font-weight: 600;
						padding: 13px 23px 10px;
						// border-radius: 5px;
						height: 50px;
						cursor: pointer;
						border: 3px solid $main-dark;

						&:hover {
							color: $main-dark;
							border: 3px solid $main-dark;
							background-color: #fff;
							box-sizing: border-box;
						}
					}
				}

		  	.notes{
		  		margin-top: 60px;

		  		div{
		  			width: 200px;
		  			margin-bottom: 15px;
		  		}

			  	.greenprice{
			  		color: $main-dark;
			  	}

			  	.boldpremium{
			  		font-weight: bold;
			  	}
				}			  
		  }
		}

	}
}