.app{
	height: 100%;

	.container{
		width: 1280px;
		margin: 0 auto;

		@media (max-width: 1279px) {
		  width: 100%;
		}
	}
}