.landing {
	.home-wrapper {
		width: calc(100% - 80px);
		padding: 40px;

		h2 {
			font-size: 30px;
			text-align: center;
			// font-weight: 900;
			margin-bottom: 20px;
			color: $light-black;

			@media (max-width: 767px) {
				span{
					display: block;
				}
			}
		}

		.buttons {
			padding-top: 30px;
			margin: 0 auto;
			width: 224px;

			&.doit{
				width: 170px;
			}

			.button {
				text-align: center;
				background-color: $main-dark;
				color: #fff;
				font-size: 14px;
				font-weight: 600;
				padding: 13px 23px 3px;
				// border-radius: 5px;
				height: 50px;
				cursor: pointer;
				border: 3px solid $main-dark;

				&:hover {
					color: $main-dark;
					border: 3px solid $main-dark;
					background-color: $background;
					box-sizing: border-box;
				}
			}

			.devbutton{
				text-align: center;
				color: $main-dark;
				border: 3px solid $main-dark;
				background-color: $background;
				box-sizing: border-box;
				font-size: 14px;
				font-weight: 600;
				padding: 13px 13px 5px;
				display: block;
			}
		}

		.show {
			color: #eee;
			position: relative;
			border: 1px solid #eee;
			padding: 20px 0 0;
			box-sizing: border-box;
			width: 90%;
			margin: 60px auto 0;

			&.deviceshow{
				border: 0px;
			}

			.show-header {
				margin-top: 100px;
			}

			img {
				width: 98%;
				padding: 1%;
			}

			dots {
				position: absolute;
				top: 0.4rem;
				left: 0;
				right: 0;
				border-bottom: 1px solid #eee;
				padding-left: 0.4rem;

				dot {
					display: block;
					float: left;
					margin-right: 5px;
					margin-bottom: 0.3rem;
					border-radius: 100%;
					width: 0.25rem;
					height: 0.25rem;
					border: 1px solid #eee;
					background: 0 0;
					box-shadow: none;
				}
			}

			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}

	.description {
		background-color: #fff;

		.description-wrapper {
			width: calc(100% - 80px);
			padding: 60px 40px;

			h1 {
				color: $light-black;
				font-weight: 500;
				font-size: 30px;
				margin-bottom: 5px;
				text-align: center;
			}

			h2 {
				color: $light-grey;
				font-weight: 400;
				font-size: 20px;
				text-align: center;
				font-family: "Avenir";
			}

			.features {
				width: 100%;

				.block {
					float: left;
					width: 33%;
					padding: 40px 60px;
					box-sizing: border-box;

					i {
						font-size: 40px;
						color: $main-dark;
						margin: 0 auto 10px;
						display: block;
						width: 25px;
					}

					h3 {
						font-size: 22px;
						text-align: center;
						color: $light-black;
						margin-bottom: 10px;
					}

					a {
						color: $light-grey;
						font-size: 16px;
						text-align: center;
						margin-bottom: 10px;
						letter-spacing: 1px;
						display: block;
						line-height: 22px;
						font-family: "Avenir";
					}

					@media (max-width: 767px) {
						width: 100%;
						padding: 20px 0px;
					}
				}
			}

			.buttons {
				padding-top: 30px;
				margin: 0 auto 25px;
				width: 110px;

				.button {
					text-align: center;
					background-color: $main-dark;
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					padding: 13px 23px 3px;
					// border-radius: 5px;
					height: 50px;
					cursor: pointer;
					border: 3px solid $main-dark;

					&:hover {
						color: $main-dark;
						border: 3px solid $main-dark;
						background-color: #fff;
						box-sizing: border-box;
					}
				}
			}
		}
	}

	.devices {
		background-color: $main-dark;

		.devices-wrapper {
			width: calc(100% - 80px);
			padding: 60px 40px;
			color: #fff;

			.left {
				width: 45%;

				.left-wrapper {
					width: 70%;
					margin: 0 auto;
				}

				h1.everyday {
					margin-top: 30px;
					font-size: 60px;
					font-weight: 500;
					text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
				}

				h1.everywhere {
					font-size: 60px;
					font-weight: 500;
					text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
				}

				.buttons {
					margin-top: 30px;

					.iphone,
					.android {
						color: #fff;
						font-size: 14px;
						margin-right: 20px;
						background-color: #fff;
						padding: 13px 23px 3px;
						color: $main-dark;

						span {
							margin-top: -4px;
						}

						i {
							font-size: 24px;
							margin-left: 10px;
							position: relative;
							top: 3px;
						}

						&:hover {
							opacity: 0.5;
							cursor: pointer;
						}
					}
				}

				.text {
					font-family: "Avenir";
					margin-top: 30px;
					font-size: 16px;
					margin-bottom: 10px;
					letter-spacing: 1px;
					display: block;
					line-height: 22px;

					.devices {
						color: #fff;
						text-decoration: underline;

						&:hover {
							opacity: 0.5;
						}
					}
				}
			}

			.right {
				width: 90%;
				position: relative;
				// top: -40px;

				.change-device {
					padding-top: 30px;
					color: rgba(0, 0, 0, 0.2);
					font-size: 12px;
					width: 110px;
					text-transform: uppercase;
					font-weight: 700;
					position: absolute;
					right: 0;
					bottom: 100px;
					font-family: "Avenir";
					font-weight: 500;

					&:hover {
						cursor: pointer;
						opacity: 0.5;
					}

					i {
						color: #fff;
						font-size: 30px;
						display: block;
						margin-bottom: 5px;
						transform: rotate(90deg);
						width: 20px;
					}
				}

				.iphone {
					right: -20px;
					bottom: -160px;
					width: 627px;
					height: 395px;
					position: absolute;
				}

				.android {
					right: 0;
					bottom: -200px;
					opacity: 1;
					width: 584px;
					height: 467px;
					position: absolute;
				}

				.webapp{
					color: #eee;
					position: relative;
					border: 1px solid #eee;
					padding: 17px 0 0;
					box-sizing: border-box;
					margin: 60px auto 0;
					line-height: 0;

					&.androidshow{
						border: 0px;
					}

					.show-header {
						margin-top: 100px;
					}

					img {
						width: 100%;
						display: block;
					}

					dots {
						position: absolute;
						top: 0.4rem;
						left: 0;
						right: 0;
						border-bottom: 1px solid #eee;
						padding-left: 0.4rem;

						dot {
							display: block;
							float: left;
							margin-right: 5px;
							margin-bottom: 0.3rem;
							border-radius: 100%;
							width: 0.25rem;
							height: 0.25rem;
							border: 1px solid #eee;
							background: 0 0;
							box-shadow: none;
						}
					}
			  }

				span {
					text-transform: uppercase;
					color: #fff;
				}
			}
		}

		@media (max-width: 1200px) {
			.devices-wrapper { // necessary for css weights

				.right{
					.change-device {
						right: -90px;
					}

					.iphone {
						right: -120px;
						bottom: -160px;
						width: 627px;
						height: 395px;
						position: absolute;
					}

					.android {
						right: -80px;
						bottom: -200px;
						opacity: 1;
						width: 584px;
						height: 467px;
						position: absolute;
					}
				}
			}
		}

		@media (max-width: 1023px) {
			.devices-wrapper {
				width: 100%;
				padding: 60px 20px;
				box-sizing: border-box;

				.left{
					width: 100%;
					height: 300px;
					display: block;

					.left-wrapper{
						width: 100%;
					}


					.buttons{
						.iphone, .android{
							padding: 13px 21px 5px;
						}
					}
				}

				.right{
					width: 100%;
					margin-top: 20px;
					display: block;
					position: relative;

					.change-device {
						bottom: 80%;
						right: 15%;
					}

					> div{
						height: 100%;
						width: 100%;
						margin: 0 auto;
					}

					.iphone {
						width: 80%;
						height: auto;
						right: initial;
						bottom: initial;
						position: static;
						margin: 0 auto;
					}

					.android {
						opacity: 1;
						width: 80%;
						height: auto;
						left: 0;
						top: 0;
						right: initial;
					  bottom: initial;
						position: absolute;
						margin: 0 auto;
					}
				}
			}
		}

		@media(max-width: 400px){
			.devices-wrapper {
				.left {
					h1.everyday {
						margin-top: 30px;
						font-size: 50px;
						font-weight: 500;
						text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
					}

					h1.everywhere {
						font-size: 50px;
						font-weight: 500;
						text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
					}
				}
			}
		}
	}

	.signup-landing {
		// background-color: $main-dark;

		.signup-wrapper {
			width: calc(100% - 80px);
			padding: 60px 40px;
		}
	}
}
