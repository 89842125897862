.features-page {
	background-color: #fff;

	.wrapper {
		padding: 60px 40px;
		width: calc(100% - 80px);

		h1 {
			color: $black;
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 20px;
			text-align: center;
		}

		h2 {
			margin: 0 auto;
			width: 330px;
			color: $light-grey;
			font-size: 22px;
			text-align: center;
			margin-bottom: 20px;
			line-height: 34px;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.features {
			width: 100%;
			padding: 10px 0;

			.feature {
				padding: 60px 0;
				border-bottom: 2px solid $background;

				&:last-child {
					border-bottom: none;
				}

				@media (max-width: 767px) {
					&:first-child {
						padding: 0 0 60px 0;
					}

					&:last-child {
						padding: 60px 0 0 0;
					}
				}
			}

			.block {
				float: left;
				width: 50%;
				box-sizing: border-box;

				&.a {
					width: 35%;
				}

				&.b {
					width: 65%;
				}

				&.c {
					width: 45%;
				}

				&.d {
					width: 55%;
					margin-top: 100px;
				}

				&.devices {
					position: relative;
					display: inline-block;
					min-height: 400px;

					.iphone {
						width: 627px;
						height: 395px;
						position: absolute;
						top: 0;
						left: 0;
					}

					.android {
						position: absolute;
						top: 0;
						left: 0;
						width: 584px;
						height: 467px;
					}

					@media (max-width: 767px) {
						min-height: 200px;

						.iphone {
							width: 100%;
							height: auto;
							position: absolute;
							top: 0;
							left: 0;
						}

						.android {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: auto;
						}
					}
				}

				i {
					color: $main-dark;
					font-size: 50px;
					display: block;
					margin-bottom: 10px;
				}

				h3 {
					font-size: 22px;
					color: $light-black;
					margin-bottom: 10px;
					line-height: 30px;
				}

				a {
					color: $light-grey;
					font-size: 16px;
					margin-bottom: 10px;
					letter-spacing: 1px;
					display: block;
					line-height: 30px;
					font-family: "Avenir";
				}

				.line {
					margin-top: 18px;
					height: 3px;
					width: 50px;
					background-color: $main-dark;
				}

				img {
					width: 100%;
					// border-radius: 4px;
				}

				@media (max-width: 767px) {
					width: 100%;

					&.a {
						width: 100%;
					}

					&.b {
						width: 100%;
					}

					&.c {
						width: 100%;
					}

					&.d {
						width: 100%;
						margin-top: 0px;
					}

					&.withpadding {
						padding: 20px 0px;
					}
				}

				.noblock {
					display: inline-block;
					margin-right: 8px;
				}

				.link {
					color: $main-dark;
					display: inline-block;

					&:hover {
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}

			.withpadding {
				padding: 20px 60px;
			}

			.withborder {
				border: 1px solid #eee;
			}
		}
	}
}
