// colors
$black: #212121;
$grey: rgba(0, 0, 0, 0.44);

$background: #fff;
$main-font-color: #6f7a80;
$light-grey: #a8afb3;
$light-black: #1c242b;

$border: #dfe3e6;
$border-inner: #fff;

$menuBackground: #fff;

// greens

$main-dark: #249c03;
$main: #2fcf03;

// greenscale 21
$green1: #efffeb;
$green2: #dffed7;
$green3: #d0fec3;
$green4: #c0feaf;
$green5: #b0fd9b;
$green6: #a0fd86;
$green7: #90fd72;
$green8: #81fd5e;
$green9: #71fc4a;
$green10: #61fc36;
$green11: #51fc22;
$green12: #41fb0e;
$green13: #37f104;
$green14: #33dd03;
$green15: #2fcf03;
$green16: #29b503;
$green17: #25a102;
$green18: #208d02;
$green19: #1c7902;
$green20: #176402;
$green21: #125001;
$green22: #0e3c01;
$green23: #092801;
$green24: #051400;

// greenscale 10
$greenscale1: #b0fd9b;
$greenscale2: #71fc4a;
$greenscale3: #2fcf03;
$greenscale4: #2bb305;
$greenscale5: #249c03;
$greenscale6: #1f8104;
$greenscale7: #196804;
$greenscale8: #144f03;
$greenscale9: #0e3c01;
$greenscale10: #092801;

// bluescale
$bluescale1: #b0edff;
$bluescale2: #71dcff;
$bluescale3: #3fcfff;
$bluescale4: #3bb3ff;
$bluescale5: #249cff;
$bluescale6: #1f81ff;
$bluescale7: #1968ff;
$bluescale8: #144fff;
$bluescale9: #0e3cff;
$bluescale10: #0908ff;

// orangescale
$orangescale1: #fff69b;
$orangescale2: #ffe54a;
$orangescale3: #ffd803;
$orangescale4: #ffbc05;
$orangescale5: #ffa503;
$orangescale6: #ff8a04;
$orangescale7: #ff7104;
$orangescale8: #ff5803;
$orangescale9: #ff3101;
$orangescale10: #ff0000;

// $greenscale1: lighten($main, 30%);
// $greenscale2: lighten($main, 10%);
// $greenscale3: #2FCF03;
// $greenscale4: darken($main, 3%);
// $greenscale5: darken($main, 8%);
// $greenscale6: darken($main, 10%);
// $greenscale7: darken($main, 15%);
// $greenscale8: darken($main, 20%);
// $greenscale9: darken($main, 25%);
// $greenscale10: darken($main, 30%);

// fonts

// $main-font: 'Avenir Next W01', Helvetica;
// $main-font: 'circular-black', Helvetica;
// $main-font: 'Muli', sans-serif;
$main-font: "euclid-medium", sans-serif;
