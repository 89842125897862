.devices-page {
  background-color: #fff;

  .wrapper {
    padding: 60px 40px 0px;
    width: calc(100% - 80px);

    h1 {
      color: $black;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 40px;
      text-align: center;
    }

    ul {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0 auto;
      margin-bottom: 25px;
      max-width: 940px;

      li.box {
        background-color: #fff;
        box-shadow: 0 4px 35px rgba(0, 0, 0, 0.05),
          0 2px 5px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 31px;
        text-align: center;
        width: 300px;

        &.solo {
          width: 460px;
          // margin: 0 auto;
          padding-bottom: 0;
        }

        ul {
          width: 100%;

          li {
            width: 100%;
            margin-bottom: 30px;

            &.extramargin {
              margin-bottom: 40px;
            }

            &.extramargin2 {
              margin-bottom: 60px;
            }
          }
        }
      }

      h1 {
        color: #4c4c4d;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      h2 {
        color: #6d6f6f;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .device-image {
        height: 76px;
        margin-bottom: 51px;
        margin-top: 39px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .browser-image {
        background-image: url(/images/devices/browser.svg);
      }

      .app-image {
        background-image: url(/images/devices/mobile.svg);
      }

      .webext-image {
        background-image: url(/images/devices/extensions.svg);
      }

      .desktop-image {
        background-image: url(/images/devices/desktop.svg);
      }

      .watch-image {
        background-image: url(/images/devices/wearables.svg);
      }

      .button-devices {
        background-color: $main-dark;
        color: #fff;
        margin: 0 auto;
        width: 100px;
        padding: 10px 23px 0px;
        border: 3px solid $main-dark;
        display: block;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: $main-dark;
          border: 3px solid $main-dark;
          // box-sizing: border-box;
          width: 100px;
        }
      }
    }
  }
}
