.featured-page{
	background-color: #fff;

  .wrapper{
			padding: 60px 40px;
			width: calc(100% - 80px);


			h1{
				color: $black;
				font-size: 30px;
				font-weight: 500;
				margin-bottom: 20px;
				text-align: center;
			}

			h2{
				margin: 0 auto;
		    width: 400px;
		    color: $light-grey;
		    font-size: 22px;
		    text-align: center;
		    margin-bottom: 20px;

		    @media (max-width: 767px) {
					width: 100%;
				}
			}

			.text{
        font-size: 16px;
        line-height: 1.5em;
        text-align: justify;

        p{
        	padding: 10px 0;
        }

        span{
        	width: 200px;
        	display: inline-block;
        }

        a{
        	color: $main-dark;
					font-weight: 900;

					&:hover{
						cursor: pointer;
						text-decoration: underline;
					}
        }

        img{
        	max-width: 800px;
        	margin: 20px 0 20px 200px;
        }

        @media (max-width: 767px) {
        	text-align: left;

					a{
						display: block;
						padding: 0;
					}

					img{
						width: 100%;
						margin: 20px 0;
					}
				}
			}
		}
}