.notfound-page{
	background-color: #fff;

	.wrapper{
		padding: 60px 40px;
		width: calc(100% - 80px);

		h1{
			color: $black;
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 20px;
			text-align: center;
		}

		a{
			font-size: 16px;
			line-height: 1.5em;

			&.link{
				color: $main-dark;

				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}