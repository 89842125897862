@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800,900');

@font-face {
    font-family: 'euclid';
    src: url(../fonts/Euclid/euclidflex-regular-webfont.woff2),
         url(../fonts/Euclid/euclidflex-regular-webfont.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-medium';
    src: url(../fonts/Euclid/euclidflex-medium-webfont.woff2),
         url(../fonts/Euclid/euclidflex-medium-webfont.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-bold';
    src: url(../fonts/Euclid/euclidflex-bold-webfont.woff2),
         url(../fonts/Euclid/euclidflex-bold-webfont.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}