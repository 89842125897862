.reset{
	h2{
		color: $black;
		font-size: 30px;
		font-weight: 500;
		margin-bottom: 30px;
		text-align: center;
	}

	.reset-form-wrapper{
		margin: 0 auto;
		width: 400px;

		@media (max-width: 460px) {
		  width: 100%;
		}

    .errors{
      list-style: none;
      color: #fff;
      margin-bottom: 20px;
      background-color: $main-dark;
      padding: 5px;
    }

    .form-row{
    	margin-bottom: 20px;
    	position: relative;
    	width: 100%;

    	.password-input{
    		background-color: #fff;
    		border: 2px solid $light-grey;
    		// border-radius: 5px;
    		color: $black;
    		font-size: 16px;
    		height: 70px;
    		padding: 33px 13px 13px;
    		transition: border-color 0.1s ease 0s;
    		width: 100%;
    		box-sizing: border-box;
    	}

    	.text-label{
    		display: block;
    		font-size: 14px;
    		font-weight: 600;
    		left: 15px;
    		// overflow: hidden;
    		padding-right: 30px;
    		pointer-events: none;
    		position: absolute;
        text-overflow: ellipsis;
        top: 19px;
        transition: color 0.1s ease 0s;
        white-space: nowrap;
        width: 100%;
    	}

    	input:focus{
      	border-color: $main-dark;

      	~ .text-label{
      		color: $main-dark;
      	}
      }
    }

    .send-button{
  		display: block;
  		background-color: $main-dark;
  		color: #fff;
  		width: 100%;
  		border: 2px solid transparent;
  		// border-radius: 5px;
  		cursor: pointer;
  		font-size: 14px;
  		font-weight: 600;
  		height: 50px;
  		line-height: 20px;
  		padding: 13px 23px;
  		text-align: center;

  		&:hover{
	  		color: $main-dark;
	  		border: 3px solid $main-dark;
	  		background-color: $background;
	  		box-sizing: border-box;
	  	}
  	}
	}
}